"use client"

import { useEffect, useState } from "react"
import { useOrganization, useUser } from "@clerk/nextjs"
import { useRouter } from "next-nprogress-bar"

import { useMounted } from "@/hooks/use-mounted"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"

export function FeedDialog() {
  const [showDialog, setShowDialog] = useState(true)
  const router = useRouter()
  const { user, isLoaded: userIsLoaded } = useUser()
  const { organization, isLoaded: organizationIsLoaded } = useOrganization()
  const mounted = useMounted()

  const membershipPlan = user?.publicMetadata.membershipPlan

  const onClose = (): void => {
    localStorage.setItem("firstTimeFeed", "false")
    setShowDialog(false)
  }

  useEffect(() => {
    if (!localStorage.getItem("firstTimeFeed")) {
      localStorage.setItem("firstTimeFeed", "true")
    }
  }, [])

  if (!mounted) return null

  return localStorage.getItem("firstTimeFeed") === "true" &&
    (membershipPlan === "professional" ||
      membershipPlan === "enterprise" ||
      organization) &&
    userIsLoaded &&
    organizationIsLoaded &&
    showDialog ? (
    <Dialog open={showDialog} onOpenChange={onClose} modal>
      <DialogContent className="space-y-2">
        <DialogHeader>
          <DialogTitle className="mb-2 leading-tight">
            ¡Tenemos clientes esperando tu contacto!
          </DialogTitle>
          <DialogDescription asChild>
            <div className="text-md text-gray-800">
              <p>
                Conéctate con usuarios altamente interesados en comprar o
                alquilar propiedades.{" "}
              </p>
              <br />
              <p>
                Explora nuestra nueva sección y accede a clientes potenciales
                que buscan activamente su próximo hogar. ¡No dejes pasar esta
                oportunidad de cerrar más tratos!
              </p>
            </div>
          </DialogDescription>
        </DialogHeader>
        <div className="flex items-end justify-end gap-4 sm:gap-6">
          <Button onClick={onClose} className="text-md" variant="outline">
            Cerrar
          </Button>
          <Button
            onClick={() => (onClose(), router.push("/feed"))}
            className="text-md"
          >
            Explorar Leads
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  ) : null
}
